.sm-page-widget-feed .sm-feed {
  max-width: 100%;
}
.sm-page-widget-feed .sm-feed-item {
  margin: 0 0 72px;
  overflow: hidden;
}
.sm-page-widget-feed .sm-feed-item .sm-feed-item-title {
  display: inline-block;
}
.sm-page-widget-feed .sm-feed-item .sm-feed-item-description {
  margin-top: 8px;
}
.sm-page-widget-feed .sm-feed-item img {
  max-width: 100%;
}
@media screen and (max-width: 736px) {
  .sm-page-widget-feed .sm-feed-item .sm-feed-item-date {
    -webkit-text-size-adjust: none;
  }
  .sm-page-widget-feed .sm-feed-item .sm-feed-item-description {
    font-size: 16px;
    -webkit-text-size-adjust: none;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi9jb2RlYnVpbGQvb3V0cHV0L3NyYzMwMzIvc3JjLzRmYTNiNDZjXzg1MWNfNDhiZV9hM2E4XzBmYTMyY2IzYmZmYy9hY3Rpb25zLXJ1bm5lci9fd29yay9TbXVnTXVnL1NtdWdNdWcvZG9jcm9vdC9pbmNsdWRlL2Nzcy9zbXVnbXVnL3dpZGdldHMvZmVlZC5sZXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBLG9CQUFxQjtFQUNqQixlQUFBOztBQUdKLG9CQUFxQjtFQUNqQixnQkFBQTtFQUNBLGdCQUFBOztBQUdKLG9CQUFxQixjQUFjO0VBQy9CLHFCQUFBOztBQUdKLG9CQUFxQixjQUFjO0VBQy9CLGVBQUE7O0FBR0osb0JBQXFCLGNBQWM7RUFDL0IsZUFBQTs7QUFHSixtQkFBZ0Q7RUFDNUMsb0JBQXFCLGNBQWM7SUFDL0IsOEJBQUE7O0VBR0osb0JBQXFCLGNBQWM7SUFDL0IsZUFBQTtJQUNBLDhCQUFBIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIi4uL3ZhcmlhYmxlc1wiO1xuXG4uc20tcGFnZS13aWRnZXQtZmVlZCAuc20tZmVlZCB7XG4gICAgbWF4LXdpZHRoOiAxMDAlO1xufVxuXG4uc20tcGFnZS13aWRnZXQtZmVlZCAuc20tZmVlZC1pdGVtIHtcbiAgICBtYXJnaW46IDAgMCA3MnB4O1xuICAgIG92ZXJmbG93OiBoaWRkZW47XG59XG5cbi5zbS1wYWdlLXdpZGdldC1mZWVkIC5zbS1mZWVkLWl0ZW0gLnNtLWZlZWQtaXRlbS10aXRsZSB7XG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xufVxuXG4uc20tcGFnZS13aWRnZXQtZmVlZCAuc20tZmVlZC1pdGVtIC5zbS1mZWVkLWl0ZW0tZGVzY3JpcHRpb24ge1xuICAgIG1hcmdpbi10b3A6IDhweDtcbn1cblxuLnNtLXBhZ2Utd2lkZ2V0LWZlZWQgLnNtLWZlZWQtaXRlbSBpbWcge1xuICAgIG1heC13aWR0aDogMTAwJTtcbn1cblxuQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogQHNtYWxsLW1heC13aWR0aCkge1xuICAgIC5zbS1wYWdlLXdpZGdldC1mZWVkIC5zbS1mZWVkLWl0ZW0gLnNtLWZlZWQtaXRlbS1kYXRlIHtcbiAgICAgICAgLXdlYmtpdC10ZXh0LXNpemUtYWRqdXN0OiBub25lO1xuICAgIH1cblxuICAgIC5zbS1wYWdlLXdpZGdldC1mZWVkIC5zbS1mZWVkLWl0ZW0gLnNtLWZlZWQtaXRlbS1kZXNjcmlwdGlvbiB7XG4gICAgICAgIGZvbnQtc2l6ZTogMTZweDtcbiAgICAgICAgLXdlYmtpdC10ZXh0LXNpemUtYWRqdXN0OiBub25lO1xuICAgIH1cbn1cbiJdfQ== */